// import NextLink from 'next/link'
// import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ReactElement } from 'react'

import { Home } from '@/contents'
import { Main } from '@/layouts'
import { makeStaticProps } from '@/lib/getStatic'
import { NextPageWithLayout } from '@/pages/_app'

// config topbar
/*topbar.config({
  barColors: {
    0: 'rgba(26,  188, 156, .7)',
    .3: 'rgba(41,  128, 185, .7)',
    1.0: 'rgba(231, 76,  60,  .7)'
  },
  shadowBlur: 0
});*/

const HomePage: NextPageWithLayout = () => <Home />

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <Main>{page}</Main>
}

export default HomePage

const getStaticProps = makeStaticProps()

export { getStaticProps }
